import { ProfileType } from '@rma/accounts/user';

export interface AccountCreationRequest {
  country?: string;
  industry?: string;
  role?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agencyName?: string;
  narNumber?: string;
}
export interface LoginResponse {
  userToken: string;
  userId: string;
  redirectUrl: string;
  errorMessage: string;
  loginSuccessful: boolean;
}
export interface ResetResponse {
  isNotSuccessful: boolean;
  isSuccessful: boolean;
  status: string;
}

export interface UserDetails {
  alias: string;
  email: string;
  firstname?: string;
  lastname?: string;
}

export enum SocialMediaChannel {
  Facebook = 1,
}

export interface IdentityToken {
  id_token: string;
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
}

export interface RegisterRequest {
  email: string;
  password: string;

  firstName?: string;
  lastName?: string;
}

export interface LoginTokenResponse {
  userToken?: string;
  redirectUrl?: string;
  errorMessage?: string;
  loginSuccessful: boolean;
  profileType?: ProfileType;
  profileCode?: string;
}

export interface OidcResponse {
  userToken: string;
  profileType: string;
  profileCode: string;
  redirectUrl: string;
  loginSuccessful: boolean;
}
// could be agent, agency, or consumer!
export interface EmailLookupResponse {
  agentProfile?: {
    agentProfileId: number;
    agentId: string;
    agentCode: string;
    agencyName: string;
    fullName: string;
    firstName: string;
    lastName: string;
    isClaimed: boolean;
  };
  agencyProfiles?: [
    {
      agencyProfileId: number;
      agencyId: string;
      agencyCode: string;
      name: string;
      isClaimed: boolean;
    },
  ];
  avatarUrl?: string;
  userId: string;
  email: string;
  alias: string;
  firstname: string;
  lastname: string;
  name: string;
  isAnyProfileClaimed: boolean;
  isConsumer: boolean;
}
